/* MarkdownWithTabs.css */
.mermaid {
  contain: content;
  will-change: transform;
  min-height: 50px;
  min-width: 50px;
}

/* Disable mouse wheel zoom/pan on SVG */
.mermaid svg {
  pointer-events: none !important;
}

.markdown-container {
  align-items: start;
  flex-shrink: 0;
  background-color: white;
  pointer-events: none !important;
}

.markdown-header {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center; 
  padding: 10px;
  box-sizing: border-box;
  background-color: lightgray;
}

.markdown-header-content {
  display: flex;
  min-height: 50px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0; /* Prevent shrinking */
}

.markdown-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0; /* Prevent shrinking */
  background-color: #666;
}

.markdown-button :hover {
  background-color: #999;
}

.markdown-content {
  flex-grow: 1; /* Allow the chat container to grow and shrink dynamically */
  padding: 20px;
  width: 59rem;
  max-width: 100%;
  align-self: center;
  overflow-y: visible;
}

.markdown-content svg,
.mermaid svg {
  max-height: 50dvh;
}