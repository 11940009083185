@tailwind base;
@tailwind components;
@tailwind utilities;

:host, html {
  text-size-adjust: 100%;
  font-feature-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-variation-settings: normal;
  line-height: 1.5;
  tab-size: 4;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  height: 1rem;
  width: .5rem
}

::-webkit-scrollbar:horizontal {
  height: .5rem;
  width: 1rem
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px
}

::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: hsla(0,0%,89%,.8);
  border-color: rgba(255,255,255,var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px
}

::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(227,227,227,var(--tw-bg-opacity))
}

.App {
  text-align: center;
  width: 100vw;
  min-height: 99dvh;
  max-height: 99dvh;
  margin: 0 auto;
  display: flex;
  align-items: start;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  min-height: 99dvh;
  max-height: 99dvh;
}

.code-container {
  background-color: #1d1f21;
  border: 1px solid #444;
  border-radius: 5px;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.image-grid {
  display: grid;
  width: auto;
  max-width: 95%;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Space between images */
}

.image-grid img {
  width: auto;
  max-width: 95%; /* Adjust max-width to limit the image size */
  height: auto;
  max-height: 95%; /* Adjust max-width to limit the image size */
  border-radius: 8px;
  object-fit: contain; /* Ensure the image scales within bounds without distortion */
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(41, 41, 41, 0.8); /* Dark overlay */
  z-index: 1000; /* Ensure it's on top of all other content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.konvajs-content {
  margin: auto;
}

.modal-content {
  position: relative;
  max-width: 98vw;
  max-height: 98vh;
  background: rgba(250, 250, 250, 0.9);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(32, 32, 32, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close-modal-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
  border-radius: 50%;
  border: none;
  color: gray;
  font-size: 16px;
  cursor: pointer;
  padding: 2px;
  z-index: 1001; /* Keep the button above everything */
}

.empty-chat-prompt {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center;     /* Center horizontally */
  min-height: 70vh;             /* Optional: Ensure full width */
  text-align: center;
}

.empty-chat-prompt h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333; /* Darker text for light mode */
}

.suggestion-buttons {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to a new row */
  gap: 20px; /* Space between buttons */
  justify-content: center;
}

.suggestion-buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between icon and text */
  padding: 8px 0px;
  border: 2px solid #444; /* Outline for light mode */
  background-color: transparent;
  color: #444;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  flex: 1 1 150px;
  max-width: 190px;
  min-width: 160px;
}

.suggestion-buttons button:hover {
  background-color: #f0f0f0;
  color: #222;
  border-color: #222; /* Darker border on hover */
}

.suggestion-buttons button svg {
  font-size: 24px; /* Icon size */
  color: #444;
}

.suggestion-buttons button:hover svg {
  color: #222; /* Darker icon color on hover */
}

/* Container Styles */
.mermaid-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f4f4f4;
  height: 100vh; /* Full viewport height */
}

/* Header Styles */
.mermaid-header {
  display: flex;
  justify-content: space-between; /* Ensure space between buttons */
  align-items: center;  /* Vertically center buttons */
  background-color: #333;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  margin-bottom: 15px;
  flex-shrink: 0; /* Prevent shrinking of the header */
  width: 100%; /* Ensure it spans the full width */
}

/* Tab Button Styles */
.mermaid-tab-button {
  font-weight: normal;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ddd;
  background-color: #333;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-right: 10px; /* Ensure space between buttons */
  flex-shrink: 0; /* Prevent shrinking */
}

.mermaid-tab-button:hover {
  background-color: #555;
}

.mermaid-tab-button.mermaid-active {
  background-color: #fff;
  color: #333;
  font-weight: bold;
}

/* Content Styles */
.mermaid-content {
  flex-grow: 1;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-top: 10px;
  overflow: auto;
}

.mermaid-chart-content {
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
}

.mermaid-code-content {
  background-color: #f4f4f4;
  padding: 20px;
  border: 1px solid #ddd;
}

.mermaid-error {
  color: red;
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2b2d2f;
  padding: 10px;
  color: #b5b5b5;
  font-size: 0.875rem;
}

.copy-button {
  background: none;
  border: none;
  color: #61dafb;
  cursor: pointer;
  font-size: 0.875rem;
}

.copy-button:hover {
  color: #21a1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  display: inline; /* Change this to inline for default and modify as needed below */
  padding: 2px; /* Adjust padding to be less intrusive for inline code */
  background-color: #ccc; /* Optional, gives a light background to inline code */
  border-radius: 5px; /* Optional, rounds corners of the code block background */
}

pre code {
  display: block; /* Ensure full blocks of code are still formatted as blocks */
  padding: 5px; /* More padding for block code elements */
  margin: 0.2em 0; /* Space out block code from other text */
  background-color: #222; /* Maybe a different background for block code for more distinction */
  border-radius: 5px; /* Consistency with inline code radius */
}

pre {
  position: relative;
  background: #fff;
  padding: 0;
  border-radius: 5px;
  overflow: auto;
  margin-bottom: 0;
  border: none;
}

pre code {
  display: block;
  border: none;
}

.markdown-content svg,
.mermaid svg {
  max-height: 50dvh;
}

.scroll-to-bottom-button-container {
  position: fixed;
  bottom: 70px; /* Adjust this value based on the height of the ChatInput component */
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  text-align: center;
  padding: 10px;
}

.scroll-to-bottom-button {
  cursor: 'pointer';
  font-size: '24px';
  border-radius: 50%;
  background-color: #333;
  color: white;
}

.chat-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 98dvh;
  max-height: 99dvh;
  width: 100%;
  margin: 0 auto;
}

.chat-container {
  flex-grow: 1; /* Allow the chat container to grow and shrink dynamically */
  padding: 20px;
  width: 59rem;
  max-width: 100%;
  align-self: center;
  overflow-y: auto; /* Enable vertical scrolling */
}

.chat-container::-webkit-scrollbar {
  width: 8px; /* Width of the entire scrollbar */
}

.chat-container::-webkit-scrollbar-track {
  background: transparent; /* Color of the scrollbar track */
  border-radius: 10px;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Color of the scrollbar thumb */
  border-radius: 10px;
  border: 3px solid #f1f1f1; /* Adds a border around the thumb */
}

.chat-container::-webkit-scrollbar-thumb:hover {
  background-color: #bbb; /* Color when hovered */
}

.chat-input-area {
  padding: 2px 20px;
  width: 59rem;
  max-width: 100%;
  margin: 5px auto;
  background-color: #f0f0f0;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 49px;
  overflow: hidden; /* Ensure overflow is handled */
  flex-shrink: 0; /* Prevent shrinking */
}

.image-preview-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.help-page-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.chat-input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

textarea.chat-input {
  flex: 1;
  padding: 10px; /* Adjust padding */
  padding-left: 60px; /* Ensure space for the paperclip icon */
  padding-right: 60px; /* Ensure space for the cancel button */
  border-width: 0px;
  background-color: #f0f0f0;
  resize: none; /* Disable manual resize */
  overflow-y: auto; /* Ensure scrollbar appears if needed */
  max-height: 25vh; /* Set maximum height for textarea */
  box-sizing: border-box;
}

.chat-disclaimer {
  flex-grow: 1; /* Allow the chat container to grow and shrink dynamically */
  align-self: center;
  width: 59rem;
  max-width: 100%;
  font-size: 0.7rem;
  height: 1rem;
  min-height: 1rem;
  max-height: 1rem;
}

.message {
  /* max-width: 60%; */
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
  word-wrap: break-word;
  text-align: left;
  /* line-height: 1.5; */
}

.bot-message {
  background-color: #ffffff;
  align-self: flex-start;
  text-align: left;
  /* padding: 10px;
  border-radius: 10px;
  max-width: 60%; */
}

.user-message {
  background-color: #f4f4f4;
  align-self: flex-end;
  text-align: left;
}

.user-message-pre {
  background-color: #f4f4f4;
  align-self: flex-end;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  text-size-adjust: 90%;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-variation-settings: normal;
  line-height: 1.5;
  tab-size: 4;
}

.prose {
  max-width: 85ch;
}

:root {
  --tw-prose-pre-bg: #1D1F21; /* Replace with your desired color */
}

.prose :where(pre):not(:where([class~=not-prose],[class~=not-prose] *)) {
  background-color: #1D1F21;
  margin: 0 0 5px 0;
  padding: 0;
  font-size: .8em;
  font-weight: 600;
}

.prose p img {
  max-height: 40dvh;
  max-width: 95%;
}

li pre {
  background: #fff;
  border: none; /* Remove border */
}

pre button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

pre button:hover {
  background: #0056b3;
}

.text-sm {
  font-size: .8rem;
  line-height: 1.1rem;
}

.left-pane {
  width: 250px;
  background-color: #f0f0f0;
  margin: 0 0;
  padding: 10px;
  min-height: 99%;
  max-height: 99%;
  overflow-y: scroll;
  scrollbar-color: #ddd #f1f1f1; /* For Firefox */
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.left-pane::-webkit-scrollbar {
  width: 8px; /* Width of the entire scrollbar */
}

.left-pane::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 10px;
}

.left-pane::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Color of the scrollbar thumb */
  border-radius: 10px;
  border: 3px solid #f1f1f1; /* Adds a border around the thumb */
}

.left-pane::-webkit-scrollbar-thumb:hover {
  background-color: #bbb; /* Color when hovered */
}

.left-pane-content {
  width: 100%;
}

.conversations-list {
  margin-bottom: 20px;
  width: 100%;
}

.conversation-item {
  padding: 10px 0px 10px 0px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 10px;
  max-height: 3.8em;
}

.conversation-item:hover,
.conversation-item.selected {
  background-color: #e0e0e0;
}

.conversation-item input {
  flex-grow: 1;
  margin-right: 0px;
}

.new-conversation-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
/* 
.new-conversation-button {
  padding: 10px;
  background-color: white;
  color: #d3d3d3;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-conversation-button:hover {
  background-color: #a9a9a9;
} */

.context-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.context-menu-item {
  padding: 10px;
  cursor: pointer;
}

.context-menu-item:hover {
  background-color: #f0f0f0;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh; /* Ensure it doesn't go beyond viewport */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.dialog-content h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
  color: #333;
}

.dialog-body {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 20px;
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  white-space: pre-wrap; /* Preserve formatting like line breaks */
  text-align: left; /* Left-align the text */
}

.dialog-body img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.dialog-close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #888;
  transition: color 0.3s;
}

.dialog-close-button:hover {
  color: #333;
}

/* Media Queries for Responsive Design */


.menu-button {
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  background: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1000;
}

.menu-button:hover {
  background: #0056b3;
}

.app-container {
  position: relative;
  height: 100dvh;
  width: 100dvw;
}

.drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  z-index: 10;
  pointer-events: none;
}

.drag-text {
  padding: 20px;
  border: 2px dashed white;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
}

.uploading-message {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  z-index: 10;
}

.spinner {
  margin-right: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.sending-indicator {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: gray;
}

.cancel-button {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
}

.stop-icon {
  font-size: 24px;
  color: #353535;
}

.stop-icon:hover {
  color: #555;
}

.send-icon {
  font-size: 30px;
  color: #353535;
}

.send-icon:hover {
  color: #555;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center; 
  padding: 10px;
  box-sizing: border-box;
}

.assistant-profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 1; /* Allow this element to shrink if needed */
  overflow: hidden; /* Prevent overflow if the content is too wide */
}

.assistant-logo {
  height: 40px;
  width: 40px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0; /* Prevent shrinking */
}

.header-title {
  font-size: 1.5em;
  color: gray;
  margin-left: 10px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-profile {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.notification-icon-area,
.help-page-icon-area,
.user-profile {
  flex-shrink: 0; /* Prevent shrinking */
}

.profile-initials {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: '3px 3px';
  background-color: gray;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
}

.continue-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.continue-button {
  background-color: #a9a9a9; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.export-to-word-button {
  align-items: 'center';
  background-color: #f6f6f6;
  border: 1px solid #353535;
  color: 353535;
  padding: 7px 14px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.export-to-word-button:hover {
  color: #555; /* Use a more neutral color */
}

.continue-button:hover {
  background-color: #d3d3d3;
}

.file-upload {
  display: flex;
  align-items: center;
  margin-right: 8px;
  position: absolute;
  left: 0;
  height: 100%;
}

.file-message {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.file-icon {
  margin-right: 8px;
  font-size: 24px;
}

.file-message a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.paperclip-icon {
  cursor: pointer;
  font-size: 24px; /* Adjusted size */
  color: #666;
}

.notification-ico,
.help-page-icon {
  cursor: pointer;
  position: relative;
  margin-right: 10px; /* Add margin to the right for spacing */
  /* animation-name: notification-icon-animation;
  animation-duration: 3s; */
}

.notification-article-icon {
  cursor: pointer;
  position: relative;
  margin-right: 10px; /* Add margin to the right for spacing */
}

/* @keyframes notification-icon-animation {
  0%   {margin-right: 200px;}
  100%  {margin-right: 10px;}
} */

.notification-panel {
  position: absolute;
  top: 50px;
  right: 10px; /* Keep some space from the right edge */
  width: 320px;
  background-color: white;
  border: 1px solid #d3d3d3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  overflow: hidden;
}

.help-page-panel {
  position: absolute;
  top: 50px;
  right: 10px; /* Keep some space from the right edge */
  width: 700px;
  background-color: white;
  border: 1px solid #d3d3d3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  overflow: scroll;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.notification-item:hover {
  background-color: #f9f9f9;
}

.notification-left {
  display: flex;
  align-items: center;
}

.notification-icon-area,
.help-page-icon-area {
  margin-right: 8px;
  font-size: 1.5rem; /* Adjust the size of the icon */
  position: relative;
  display: inline-block;
}

.notification-count {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 1.0rem;
  font-weight: bold;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-icon,
.help-page-icon {
  height: 40px;
  width: 40px;
}

.notification-title {
  font-weight: 600;
  font-size: 0.95rem;
  color: #333;
  margin-right: 8px;
}

.notification-age {
  font-size: 0.8rem;
  color: #888;
  white-space: nowrap; /* Ensure the age stays on one line */
}

.notification-item:last-child {
  border-bottom: none;
}

/* Centering and styling the modal content */
.ModalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #282828;
  width: 400px;
  text-align: center;
}

/* Remove default focus outline and add custom focus outline */
.ModalContent:focus {
  outline: none;
  box-shadow: 0 0 0 2px orange; /* Custom orange outline */
}

/* Styling the overlay */
.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Style for the modal header */
.ModalHeader {
  font-size: 24px;
  margin-bottom: 5px;
  color: white;
}

/* Styling the button */
.ReloadButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: orange;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

/* Add styles for the paragraph if needed */
.ModalDescription {
  margin-bottom: 20px;
  font-size: 16px;
}

@media (max-width: 1099px),
       only screen and (max-width: 767px) and (orientation: portrait) {
  #root, .App {
    flex-direction: column;
    min-height: 95dvh;
    max-height: 95dvh;
  }

  .app-container {
    position: relative;
    height: 98%;
    width: 98%;
  }
  
  .header {
    flex-direction: row;
    padding: 7px;
  }

  .header-content {
    justify-content: flex-end;
    margin-right: 5px;
    flex-shrink: 0;
  }
  
  .header-title {
    font-size: 0.8em; /* Reduce font size */
    margin-left: 5px;
  }

  .profile-initials,
  .notification-icon,
  .help-page-icon {
    width: 24px;
    height: 24px;
    font-size: 0.8em;
  }

  /* .notification-icon {
    margin-right: 4px;
  } */

  .notification-count {
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: .7rem;
    min-width: 12px;
    height: 12px;
  }

  /* @keyframes notification-icon-animation {
    0%   {margin-right: 40px;}
    100%  {margin-right: 4px;}
  } */

  .assistant-profile {
    margin-left: 28px;
    flex-shrink: 1;   
  }

  .assistant-logo {
    height: 30px;
    width: 30px;
  }
  
  .left-pane {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    min-height: 95%;
    max-height: 95%;
    z-index: 100;
    background-color: #f0f0f0; /* Ensure background color is set */
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
  }

  .left-pane.open {
    display: flex;
    transform: translateX(0);
  }

  .chat-wrapper {
    max-height: 95%;
    align-items: start;
  }

  .chat-input-area {
    max-width: 95%;
  }

  textarea.chat-input {
    font-size: 0.8em;
  }
  
  .menu-button {
    display: block;
    padding: 0px 3px 0px 3px;
    margin-right: 5px; /* Ensure there's space */
    background-color: #d3d3d3;
    color: #fff; /* Dark gray icon color */
    border: none;
    border-radius: 20%;
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7em;
    transition: background-color 0.3s, color 0.3s;
    box-sizing: border-box;
  }  

  .menu-button:hover {
    background-color: #a9a9a9; /* Darker gray on hover */
    color: #fff; /* Darker icon color on hover */
  }
  
  .menu-button:focus {
    outline: none;
  }
}
